import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { LOCAL_LOGIN_ENDPOINT } from '../../configs/endpoints';
import './ForgotPasswordSuccess.scss';
export default function ForgotPasswordSuccess() {
  return (
    <div className="FP-Success">
      <Card className="FP-Success-card">
        <img src="/fp-success.png" alt="Preview" />
        <h2 className="FP-Success-card-title">Please check your mail</h2>
        <p className="FP-Success-help-text">
          {' '}
          We just mailed you with the instruction to reset your password
        </p>
        <div className="FP-Success-sign-in-container">
          <h4>
            Back to <Link to={LOCAL_LOGIN_ENDPOINT}>Sign In</Link>
          </h4>
        </div>
      </Card>
    </div>
  );
}
