import { Input, Modal } from 'antd';
import React from 'react';
import { SERVER_USER_PROFILE_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import './PhoneModal.scss';

/** interface to describe phone modal props */
interface PhoneModalProps {
  selected: string;
  saveHandler: (requested: string) => void;
  visible: boolean;
  cancelHandler: () => void;
}

const PhoneModal: React.FC<PhoneModalProps> = (props: PhoneModalProps) => {
  const { visible, cancelHandler, selected, saveHandler } = props;

  /** manages the text input state */
  const [text, setText] = React.useState<string>('');

  /** resets the input on modal visibility */
  React.useEffect(() => setText(''), [visible]);

  /**
   * handles the on change request
   * @param {React.ChangeEvent<HTMLInputElement>} event - the on change event
   */
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  /** handles the save request after confirm */
  const confirmHandler = async () => {
    if (text === '') return;
    try {
      /** create the form data request */
      const request = new FormData();
      request.append('mobile', text);
      await axioService(POST, SERVER_USER_PROFILE_ENDPOINT, request, true);
      saveHandler(text);
      setText('');
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Modal
      title="Change Phone"
      visible={visible}
      okText="save"
      onOk={confirmHandler}
      onCancel={cancelHandler}
      okButtonProps={{ disabled: text === '' }}
    >
      <div className="PhoneModal-field-container">
        <div className="PhoneModal-field-label">Existing Phone</div>
        <div className="PhoneModal-field-input">
          <Input
            disabled={true}
            value={selected}
            placeholder="Not provided yet"
          />
        </div>
      </div>
      <div className="PhoneModal-field-container">
        <div className="PhoneModal-field-label">New Phone</div>
        <div className="PhoneModal-field-input">
          <Input
            placeholder="Enter new phone"
            onPressEnter={confirmHandler}
            value={text}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PhoneModal;
