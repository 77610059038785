import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Store } from 'redux';
import { SERVER_EMAIL_VERIFY_ENDPOINT } from '../../configs/endpoints';
import {
  FlexObj,
  getSessionUserInfo,
  setSessionUserInfo,
} from '../../store/ducks/session';
import './VerifyEmail.scss';
import queryString from 'query-string';
import { Spin } from 'antd';
import { axioService, GET } from '../../services/axioService';

/** interface to describe VerifyEmail props*/
interface VerifyEmailProps {
  userInfo: FlexObj;
  setUserInfoActionCreator: typeof setSessionUserInfo;
}

const VerifyEmail = (props: VerifyEmailProps) => {
  const { userInfo, setUserInfoActionCreator } = props;
  const location = useLocation();
  const history = useHistory();
  const { id, hash } = useParams() as any;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);

  /** destroys the session token */
  React.useEffect(() => {
    const runEmailVerification = async () => {
      const expires =
        (queryString.parse(location.search)['expires'] as string) || '';

      const signature =
        (queryString.parse(location.search)['signature'] as string) || '';

      try {
        await axioService(
          GET,
          `${SERVER_EMAIL_VERIFY_ENDPOINT}/${id}/${hash}`,
          { expires, signature },
          true
        );
        setLoading(false);

        setUserInfoActionCreator({ ...userInfo, is_email_verified: true });
      } catch (Exception) {
        console.error(Exception);
        setLoading(false);
        setError(true);
      }
      setTimeout(() => history.push('/'), 1500);
    };
    runEmailVerification();
  }, []);

  return (
    <div className="VerifyEmail-container">
      <div className="VerifyEmail-title">
        {loading ? (
          <Spin size="large" />
        ) : !error ? (
          <span>
            Email verification{' '}
            <span className="VerifyEmail-success"> successful</span> !
          </span>
        ) : (
          <span>
            Email verification{' '}
            <span className="VerifyEmail-error  "> unsuccessful</span> !
          </span>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setUserInfoActionCreator: setSessionUserInfo,
};

/** connect VerifyEmail to the redux store */
const ConnectedVerifyEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);

export default ConnectedVerifyEmail;
