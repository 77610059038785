import React from 'react';
import { Form, Card, Input, Button, Spin, Alert } from 'antd';
import { connect } from 'react-redux';
import { getSessionToken } from '../../store/ducks/session';
import { Store } from 'antd/lib/form/interface';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { POST, axioService } from '../../services/axioService';
import {
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  SERVER_RESET_PASSWORD_ENDPOINT,
} from '../../configs/endpoints';
import queryString from 'query-string';
import Logo from '../../logo/Logo';
import clsx from 'clsx';

/** interface to describe Recovery props*/
interface ResetPasswordProps {
  token: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = (
  props: ResetPasswordProps
) => {
  const { token } = props;
  const location = useLocation();
  const [isPasswordFocused, setIsPasswordFocused] = React.useState<any>(false);
  const [
    isConfirmPasswordFocused,
    setIsConfirmPasswordFocused,
  ] = React.useState<any>(false);
  const passwordRef = React.useRef<any>(null);
  const passwordConfirmationRef = React.useRef<any>(null);
  const emailRef = React.useRef<any>(null);

  // React states
  /** manages loading while processing account recovery request */
  const [loading, setLoading] = React.useState<boolean>(false);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  /** success message to show if occurs */
  const [successMessage, setSuccessMessage] = React.useState<any>('');
  /** status to manage password visibility */
  const [isPasswordVisible, setIsPasswordVisible] = React.useState<boolean>(
    false
  );

  /** cycles */

  React.useEffect(() => {
    emailRef.current?.focus();
  }, []);

  /** handlers */

  /** status to manage password confirmation visibility */
  const [
    isPasswordConfirmationVisible,
    setIsPasswordConfirmationVisible,
  ] = React.useState<boolean>(false);

  /** toggles the password input visibility */
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    passwordRef.current.blur();
  };

  /** toggles the password confirmation input visibility */
  const handlePasswordConfirmationVisibility = () => {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
    passwordConfirmationRef.current.blur();
  };

  /**
   * onFinish is called when form validation passes
   * @param {any} values - containing the key-value user response
   */
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await axioService(
        POST,
        SERVER_RESET_PASSWORD_ENDPOINT,
        {
          token: queryString.parse(location.search)['token'] || '',
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
        false
      );
      setLoading(false);
      window.location.href = '/login';
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
      setLoading(false);
      if (
        exception.response &&
        exception.response.data &&
        exception.response.data.message
      ) {
        /** create the error msg from server response */
        const errorMsg = (
          <div>
            {exception.response.data.message} <br />
            <ul>
              {exception.response.data.errors &&
                Object.keys(exception.response.data.errors).map(
                  (iterErrorField) => (
                    <li key="iterErrorField">
                      <span className="Form-alert-field">{iterErrorField}</span>{' '}
                      : {exception.response.data.errors[iterErrorField]}
                    </li>
                  )
                )}
            </ul>
          </div>
        );
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage(
          <div>
            {'There is a network error or connectivity issue!!! '}
            <br />
            {'Please try again after some time.'}
          </div>
        );
      }
    }
  };

  /**
   * onFinishFailed is called when form validation fails
   * @param {any} errorInfo - containing the respective error information
   */
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  return (
    <Spin spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Form-error"
          message="Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      {successMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Form-success"
          message="Success"
          description={successMessage}
          type="success"
          showIcon
        />
      )}
      <div className="Form-body">
        {token !== '' && <Redirect to={LOCAL_PERSONAL_INFO_ENDPOINT} />}
        <div className="Form-content">
          <div className="Form-logo-container">
            <Logo />
          </div>
          <Form
            name="ResetPassword"
            validateTrigger="onSubmit"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="Form-card">
              <h4 className="Form-card-title">Reset Password</h4>
              <Form.Item
                className={clsx(
                  'Form-card-item',
                  isPasswordFocused && 'Form-card-item-focused'
                )}
                name="password"
                rules={[
                  { required: true, message: 'Please input a password!' },
                  {
                    min: 8,
                    message: 'Password needs to be minimum 8 characters!',
                  },
                ]}
              >
                <Input
                  size="large"
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  placeholder={!isPasswordFocused ? 'New Password' : ''}
                  prefix={<img src="/lock.svg" alt="icon" />}
                  type={isPasswordVisible ? 'text' : 'password'}
                  ref={passwordRef}
                  suffix={
                    isPasswordVisible ? (
                      <i
                        onClick={handlePasswordVisibility}
                        className="fas fa-eye"
                      />
                    ) : (
                      <i
                        className="fas fa-eye-slash"
                        onClick={handlePasswordVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                className={clsx(
                  'Form-card-item',
                  isConfirmPasswordFocused && 'Form-cardd-item-focused'
                )}
                name="password_confirmation"
                rules={[
                  { required: true, message: 'Please confirm the password!' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  onFocus={() => setIsConfirmPasswordFocused(true)}
                  onBlur={() => setIsConfirmPasswordFocused(false)}
                  placeholder={
                    !isConfirmPasswordFocused ? 'Confirm Password' : ''
                  }
                  prefix={<img src="/lock.svg" alt="icon" />}
                  type={isPasswordConfirmationVisible ? 'text' : 'password'}
                  ref={passwordConfirmationRef}
                  suffix={
                    isPasswordConfirmationVisible ? (
                      <i
                        onClick={handlePasswordConfirmationVisibility}
                        className="fas fa-eye"
                      />
                    ) : (
                      <i
                        className="fas fa-eye-slash"
                        onClick={handlePasswordConfirmationVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Button
                className="Form-submit-btn"
                type="primary"
                htmlType="submit"
                size="large"
              >
                Reset Password
              </Button>
              <div className="Form-signin-container">
                <h4>
                  Back to <Link to={LOCAL_LOGIN_ENDPOINT}>Sign In</Link>
                </h4>
              </div>
            </Card>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect ResetPassword to the redux store */
const ConnectedResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ConnectedResetPassword;
