/** global constants */

/** server login grant type */
export const SERVER_LOGIN_GRANT_TYPE =
  process.env.REACT_APP_LOGIN_GRANT_TYPE || 'password';

/** server login client id */
export const SERVER_LOGIN_CLIENT_ID =
  process.env.REACT_APP_LOGIN_CLIENT_ID || '2';

/** server login client secret */
export const SERVER_LOGIN_CLIENT_SECRET =
  process.env.REACT_APP_LOGIN_CLIENT_SECRET ||
  'UF9kmGh5ks1y7UOn8yqTEXkq6CvXSpekQdy9tryb';

/** server login client scope */
export const SERVER_LOGIN_CLIENT_SCOPE =
  process.env.REACT_APP_LOGIN_CLIENT_SCOPE || '*';

/** server login app name */
export const SERVER_LOGIN_APP_NAME =
  process.env.REACT_APP_LOGIN_APP_NAME || 'virtunus_auth';
