import React from 'react';
import './UpcomingBadge.scss';

const UpcomingBadge = () => {
  return (
    <div className="UpcomingBadge-container">
      Upcoming <i className="fas fa-rocket"></i>
    </div>
  );
};

export default UpcomingBadge;
