import { Avatar } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { TIPS_APP_ENDPOINT, TODO_APP_ENDPOINT } from '../../configs/endpoints';
import { FlexObj, getSessionUserInfo } from '../../store/ducks/session';
import './AppsDropdown.scss';

interface AppsDropdownInterface {
  userInfo: FlexObj;
}

const AppsDropdown: React.FC<AppsDropdownInterface> = (
  props: AppsDropdownInterface
) => {
  const { userInfo } = props;

  const navigateToTodo = () => {
    window.location.href = TODO_APP_ENDPOINT;
  };

  const navigateToTips = () => {
    window.location.href = TIPS_APP_ENDPOINT;
  };

  return (
    <div className="AppsDropdown-container">
      <div className="AppsDropdown-app-item">
        <div className="AppsDropdown-app-logo">
          <Avatar src={userInfo.avatar}>
            <div>{userInfo?.name?.slice(0, 2)}</div>
          </Avatar>
        </div>
        <div className="AppsDropdown-app-label">Account</div>
      </div>
      <div className="AppsDropdown-app-item" onClick={navigateToTips}>
        <div className="AppsDropdown-app-logo">
          <i className="fas fa-list"></i>
        </div>
        <div className="AppsDropdown-app-label">Tips</div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect AppsDropdown to the redux store */
const ConnectedAppsDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppsDropdown);

export default ConnectedAppsDropdown;
