import { Input, Modal } from 'antd';
import React from 'react';
import { SERVER_CHANGE_PASSWORD_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import './PasswordModal.scss';

/** interface to describe PasswordModal props */
interface PasswordModalProps {
  visible: boolean;
  cancelHandler: () => void;
}

const PasswordModal: React.FC<PasswordModalProps> = (
  props: PasswordModalProps
) => {
  const { visible, cancelHandler } = props;

  /** manages the new password field */
  const [text, setText] = React.useState<string>('');
  /** manages the new password confirmation field */
  const [confirmText, setConfirmText] = React.useState<string>('');
  /** manages the current password field */
  const [currentPassword, setCurrentPassword] = React.useState<string>('');

  /** resets the inputs on modal visibility change */
  React.useEffect(() => {
    setText('');
    setConfirmText('');
    setCurrentPassword('');
  }, [visible]);

  /** handles the onchange request of new password */
  const onChangeHandlerOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  /** handles the onchange request of password confirmation text */
  const onChangeHandlerTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };

  /** handles the onchange request of current password */
  const onChangeHandlerThree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
  };

  /** handles the save request after confirm */
  const confirmHandler = async () => {
    if (
      text === '' ||
      confirmText === '' ||
      text !== confirmText ||
      text.length < 8 ||
      currentPassword.length < 8
    )
      return;

    try {
      /** create the form data request */
      const request = new FormData();
      request.append('current_password', currentPassword);
      request.append('password', text);
      request.append('password_confirmation', confirmText);
      await axioService(POST, SERVER_CHANGE_PASSWORD_ENDPOINT, request, true);
      setConfirmText('');
      setText('');
      setCurrentPassword('');
      cancelHandler();
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Modal
      title="Change Password"
      visible={visible}
      okText="save"
      onOk={confirmHandler}
      onCancel={cancelHandler}
      okButtonProps={{
        disabled:
          text === '' ||
          confirmText === '' ||
          text !== confirmText ||
          text.length < 8 ||
          currentPassword.length < 8,
      }}
    >
      <div className="PasswordModal-field-container">
        <div className="PasswordModal-field-label">Current Password</div>
        <div className="PasswordModal-field-input">
          <Input.Password
            value={currentPassword}
            placeholder="Current password"
            onChange={onChangeHandlerThree}
            iconRender={(visible) =>
              visible ? (
                <div>
                  <i className="fas fa-eye-slash" />
                </div>
              ) : (
                <div>
                  <i className="fas fa-eye" />
                </div>
              )
            }
          />
        </div>
      </div>
      <div className="PasswordModal-field-container">
        <div className="PasswordModal-field-label">New Password</div>
        <div className="PasswordModal-field-input">
          <Input.Password
            value={text}
            placeholder="New password"
            onChange={onChangeHandlerOne}
            iconRender={(visible) =>
              visible ? (
                <div>
                  <i className="fas fa-eye-slash" />
                </div>
              ) : (
                <div>
                  <i className="fas fa-eye" />
                </div>
              )
            }
          />
        </div>
      </div>
      <div className="PasswordModal-field-container">
        <div className="PasswordModal-field-label">Confirm Password</div>
        <div className="PasswordModal-field-input">
          <Input.Password
            placeholder="Retype new password"
            onPressEnter={confirmHandler}
            value={confirmText}
            onChange={onChangeHandlerTwo}
            iconRender={(visible) =>
              visible ? (
                <div>
                  <i className="fas fa-eye-slash" />
                </div>
              ) : (
                <div>
                  <i className="fas fa-eye" />
                </div>
              )
            }
          />
        </div>
      </div>
      <div className="PasswordModal-error">
        Password needs to be minimum 8 characters
      </div>
    </Modal>
  );
};

export default PasswordModal;
