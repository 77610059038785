import { Input, Modal } from 'antd';
import React from 'react';
import { SERVER_USER_PROFILE_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import './NameModal.scss';

interface NameModalProps {
  selected: string;
  saveHandler: (requested: string) => void;
  visible: boolean;
  cancelHandler: () => void;
}

const NameModal: React.FC<NameModalProps> = (props: NameModalProps) => {
  const { visible, cancelHandler, selected, saveHandler } = props;

  /** manages the state of input text */
  const [text, setText] = React.useState<string>('');

  /** resets text if modal opens/closes */
  React.useEffect(() => setText(''), [visible]);

  /**
   * handles the onChange request
   * @param {React.ChangeEvent<HTMLInputElement>} event - the react onchange event
   */
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  /** confirms the save request */
  const confirmHandler = async () => {
    if (text === '') return;
    try {
      /** create the form data request */
      const request = new FormData();
      request.append('name', text);
      await axioService(POST, SERVER_USER_PROFILE_ENDPOINT, request, true);
      saveHandler(text);
      setText('');
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Modal
      title="Change Name"
      visible={visible}
      okText="save"
      onOk={confirmHandler}
      onCancel={cancelHandler}
      okButtonProps={{ disabled: text === '' }}
    >
      <div className="NameModal-field-container">
        <div className="NameModal-field-label">Existing Name</div>
        <div className="NameModal-field-input">
          <Input disabled={true} value={selected} />
        </div>
      </div>
      <div className="NameModal-field-container">
        <div className="NameModal-field-label">New Name</div>
        <div className="NameModal-field-input">
          <Input
            placeholder="Enter new name"
            onPressEnter={confirmHandler}
            value={text}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NameModal;
