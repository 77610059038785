import clsx from 'clsx';
import React from 'react';
import './ProfileItemView.scss';

interface ProfileItemViewProps {
  label: string;
  value: string;
  requestedHandler?: () => void;
}

const ProfileItemView: React.FC<ProfileItemViewProps> = (
  props: ProfileItemViewProps
) => {
  const { label, value, requestedHandler } = props;

  return (
    <div className="ProfileItemView-container">
      <div className="ProfileItemView-label">
        <div>{label}</div>
      </div>
      <div
        className={clsx({
          'ProfileItemView-value': true,
          'ProfileItemView-value-empty': !value,
        })}
      >
        {value || `----------`}
      </div>
      {requestedHandler && (
        <div onClick={requestedHandler} className="ProfileItemView-edit-btn">
          Change {label}{' '}
        </div>
      )}
    </div>
  );
};

export default ProfileItemView;
