import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Store } from 'redux';
import { LOCAL_LOGIN_ENDPOINT } from '../../configs/endpoints';
import { isAuthenticated } from '../../store/ducks/session';

const PrivateRoute: React.FC = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => (
  <Route
    {...rest}
    // tslint:disable-next-line: jsx-no-lambda
    render={(props: any) =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: LOCAL_LOGIN_ENDPOINT,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

/** connect the component to the store */

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>, parentProps: any): any => {
  const result = {
    ...parentProps,
    isAuthenticated: isAuthenticated(state),
  };
  return result;
};

/** connect SignIn to the redux store */
const ConnectedPrivateRoute = connect(mapStateToProps, {})(PrivateRoute);

export default ConnectedPrivateRoute;
