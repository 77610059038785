import React from 'react';
import { Form, Card, Input, Button, Spin, Alert } from 'antd';
import { connect } from 'react-redux';
import { getSessionToken } from '../../store/ducks/session';
import { Store } from 'antd/lib/form/interface';
import { Link, Redirect } from 'react-router-dom';
import { POST, axioService } from '../../services/axioService';
import {
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  SERVER_FORGOT_PASSWORD_ENDPOINT,
} from '../../configs/endpoints';
import Logo from '../../logo/Logo';
import clsx from 'clsx';
import ForgotPasswordSuccess from '../../components/forgotPasswordSuccess/ForgotPasswordSuccess';

/** interface to describe Recovery props*/
interface AccountRecoveryProps {
  token: string;
}

const AccountRecovery: React.FC<AccountRecoveryProps> = (
  props: AccountRecoveryProps
) => {
  const { token } = props;
  const emailRef = React.useRef<any>(null);

  // React states
  const [isEmailFocused, setIsEmailFocused] = React.useState<boolean>(false);
  /** manages loading while processing account recovery request */
  const [loading, setLoading] = React.useState<boolean>(false);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  /** success message to show if occurs */
  const [successMessage, setSuccessMessage] = React.useState<any>('');

  /** cycles */

  React.useEffect(() => {
    emailRef.current?.focus();
  }, []);

  /** handlers */

  /**
   * onFinish is called when form validation passes
   * @param {any} values - containing the key-value user response
   */
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await axioService(
        POST,
        SERVER_FORGOT_PASSWORD_ENDPOINT,
        {
          email: values.username,
        },
        false
      );
      setSuccessMessage(response.data.message || 'Email sent!!!');
      setLoading(false);
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
      setLoading(false);
      if (
        exception.response &&
        exception.response.data &&
        exception.response.data.message
      ) {
        /** create the error msg from server response */
        const errorMsg = (
          <div>
            {exception.response.data.message} <br />
            <ul>
              {exception.response.data.errors &&
                Object.keys(exception.response.data.errors).map(
                  (iterErrorField) => (
                    <li key="iterErrorField">
                      <span className="Form-alert-field">{iterErrorField}</span>{' '}
                      : {exception.response.data.errors[iterErrorField]}
                    </li>
                  )
                )}
            </ul>
          </div>
        );
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage(
          <div>
            {'There is a network error or connectivity issue!!! '}
            <br />
            {'Please try again after some time.'}
          </div>
        );
      }
    }
  };

  /**
   * onFinishFailed is called when form validation fails
   * @param {any} errorInfo - containing the respective error information
   */
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  return (
    <Spin spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Form-error"
          message="Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <div className="Form-body">
        {token !== '' && <Redirect to={LOCAL_PERSONAL_INFO_ENDPOINT} />}
        <div className="Form-content">
          <div className="Form-logo-container">
            <Logo />
          </div>
          {successMessage ? (
            <ForgotPasswordSuccess />
          ) : (
            <Form
              name="AccountRecovery"
              validateTrigger="onSubmit"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card className="Form-card">
                <h4 className="Form-card-title">Forgot Password ?</h4>
                <h5 className="Form-help-text">
                  Enter your user account &apos;s verified email address and we
                  will send you a password reset link.
                </h5>
                <Form.Item
                  className={clsx(
                    'Form-card-item',
                    isEmailFocused && 'Form-card-item-focused'
                  )}
                  name="username"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    {
                      type: 'email',
                      message: 'Please input valid email!',
                    },
                  ]}
                >
                  <Input
                    ref={emailRef}
                    size="large"
                    onFocus={() => setIsEmailFocused(true)}
                    onBlur={() => setIsEmailFocused(false)}
                    placeholder={
                      !isEmailFocused ? 'Enter your email address' : ''
                    }
                    prefix={<img src="/envelop.png" alt="icon" />}
                  />
                </Form.Item>
                <Button
                  className="Form-submit-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Send Reset Password Email
                </Button>
                <div className="Form-signin-container">
                  <h4>
                    Back to <Link to={LOCAL_LOGIN_ENDPOINT}>Sign In</Link>
                  </h4>
                </div>
              </Card>
            </Form>
          )}
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect AccountRecovery to the redux store */
const ConnectedAccountRecovery = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRecovery);

export default ConnectedAccountRecovery;
