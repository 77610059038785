import { Modal } from 'antd';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { SERVER_AVATAR_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import './PicModal.scss';

interface PicModalProps {
  saveHandler: (requested: string) => void;
  visible: boolean;
  cancelHandler: () => void;
}

const PicModal: React.FC<PicModalProps> = (props: PicModalProps) => {
  const { visible, cancelHandler, saveHandler } = props;

  /** keeps track of the pic file */
  const [files, setFiles] = React.useState<any>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
      setFiles([]);
    },
    [visible]
  );

  /** removes the pic of current upload */
  const removeCurrentUpload = () => setFiles([]);

  /** confirms the save request */
  const confirmRequest = async () => {
    if (files.length === 0) return;
    try {
      /** create the form data request */
      const request = new FormData();
      request.append('avatar', files[0]);
      const response: any = await axioService(
        POST,
        SERVER_AVATAR_ENDPOINT,
        request,
        true
      );
      saveHandler(response.data.avatar);
      cancelHandler();
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Modal
      title="Upload Avatar"
      visible={visible}
      okText="save"
      onCancel={cancelHandler}
      onOk={confirmRequest}
      className="PicModal-modal"
      okButtonProps={{ disabled: files.length === 0 }}
    >
      <div className="PicModal-container">
        {files.length > 0 ? (
          <div className="PicModal-preview-container">
            <div className="PicModal-preview-img">
              <img src={files[0].preview} alt="" />
              <div onClick={removeCurrentUpload} className="PicModal-clear">
                <i className="fas fa-times"></i>
              </div>
            </div>
          </div>
        ) : (
          <div {...getRootProps({ className: 'PicModal-dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag and drop an image, or click to select file</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PicModal;
