import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';
import { getMaxHeight } from '../../services/utility';

/** interface for TodoFilterObj object */
export interface TodoFilterObj {
  id: string;
  title: string;
  color: string;
  order: number;
  isFavourite: boolean;
  isHidden: boolean;
  parent: string;
  expanded: boolean;
  query: string;
}

/** The reducer name */
export const reducerName = 'todoFilters';

// actions
/** action types */
export const SET_TODO_FILTERS = 'virtunus/reducer/todoFilters/SET_TODO_FILTERS';
export const SET_TODO_FILTER = 'virtunus/reducer/todoFilters/SET_TODO_FILTER';
export const FIX_TODO_FILTER_ORDERS =
  'virtunus/reducer/todoFilters/FIX_TODO_FILTER_ORDERS';

/** interface for SET_TODO_FILTERS action */
export interface SetTodoFiltersAction extends AnyAction {
  todoFilters: TodoFilterObj[];
  type: typeof SET_TODO_FILTERS;
}

/** interface for SET_TODO_FILTER action */
export interface SetTodoFilterAction extends AnyAction {
  todoFilter: TodoFilterObj;
  type: typeof SET_TODO_FILTER;
}

/** interface for FIX_TODO_FILTER_ORDERS action */
export interface FixTodoFilterOrdersAction extends AnyAction {
  parentId: string;
  type: typeof FIX_TODO_FILTER_ORDERS;
}

/** Create type for todoFilter reducer actions */
export type TodoFiltersActionTypes =
  | SetTodoFiltersAction
  | SetTodoFilterAction
  | FixTodoFilterOrdersAction
  | AnyAction;

// action creators

/** set todoFilters action creator
 * @param {TodoFilterObj} todoFilters - an array of todoFilter items
 * @returns {SetTodoFiltersAction} - an action to set todoFilters in store
 */
export const setTodoFilters = (
  todoFilters: TodoFilterObj[]
): SetTodoFiltersAction => {
  return {
    todoFilters,
    type: SET_TODO_FILTERS,
  };
};

/** set todoFilters action creator
 * @param {TodoFilterObj} todoFilter - a todoFilter object
 * @returns {SetTodoFilterAction} - an action to set todoFilter in store
 */
export const setTodoFilter = (
  todoFilter: TodoFilterObj
): SetTodoFilterAction => ({
  todoFilter,
  type: SET_TODO_FILTER,
});

export const fixTodoFilterOrders = (
  parentId: string
): FixTodoFilterOrdersAction => ({
  parentId,
  type: FIX_TODO_FILTER_ORDERS,
});

// the reducer

/** interface for todoFilters state in redux store */
type TodoFiltersState = TodoFilterObj[];

/** Create an immutable todoFilters state */
export type ImmutableTodoFiltersState = SeamlessImmutable.ImmutableArray<
  TodoFiltersState
>;

/** initial todoFilters state */
const initialState: ImmutableTodoFiltersState = SeamlessImmutable([]);

/** the todoFilters reducer function */
export default function reducer(
  state: ImmutableTodoFiltersState = initialState,
  action: TodoFiltersActionTypes
): ImmutableTodoFiltersState {
  switch (action.type) {
    case SET_TODO_FILTERS:
      return SeamlessImmutable(action.todoFilters);
    case SET_TODO_FILTER:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTodoFilter: TodoFilterObj) =>
            iterateTodoFilter.id !== action.todoFilter.id
        ),
        action.todoFilter,
      ]);
    case FIX_TODO_FILTER_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state as any,
          (iterateTodoFilter: TodoFilterObj) =>
            iterateTodoFilter.parent !== action.parentId
        ),
        ...lodash.map(
          lodash.filter(
            state,
            (iterateTodoFilter: TodoFilterObj) =>
              iterateTodoFilter.parent === action.parentId
          ),
          (iterateTodoFilter: TodoFilterObj, index: number) => ({
            ...iterateTodoFilter,
            order: index,
          })
        ),
      ]);
    default:
      return state;
  }
}

// selectors

/** returns the todoFilters list
 * @param {Partial<Store>} state - the redux store
 * @return { TodoFilterObj[] } - the existing todoFilters
 */
export function getTodoFilters(state: Partial<Store>): TodoFilterObj[] {
  return (state as any)[reducerName];
}

/** returns the todoFilters list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing todoFilters length
 */
export function getTodoFiltersLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the todoFilter obj given todoFilter id if exists; otherwise, null
 * @param {Partial<Store>} state - the redux store
 * @param {string} todoFilterId - the todoFilter id
 * @return { TodoFilterObj | null } - the existing todoFilter or null
 */
export function getTodoFilterById(
  state: Partial<Store>,
  todoFilterId: string
): TodoFilterObj {
  return lodash.find((state as any)[reducerName], { id: todoFilterId }) || null;
}

/** returns the new available order in list given a todoFilter id as parent
 * @param {Partial<Store>} state - the redux store
 * @param {string} todoFilter - the todoFilter id
 * @return { number } - the new available order
 */
export function getNewTodoFilterOrderbyTodoFilterId(
  state: Partial<Store>,
  todoFilterId: string
): number {
  return lodash.filter((state as any)[reducerName], { parent: todoFilterId })
    .length;
}

/** returns the maximum height or depth given a todoFilter id
 * @param {Partial<Store>} state - the redux store
 * @param {string} todoFilter - the todoFilter id
 * @return { number } - the maximum height
 */
export function getMaxHeightOfTodoFilter(
  state: Partial<Store>,
  todoFilterId: string
): number {
  return getMaxHeight((state as any)[reducerName], todoFilterId);
}
