import { Divider } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import ProfileItemView from '../../components/profileItemView/ProfileItemView';
import withAccountLayout from '../../hocs/withAccountLayout/withAccountLayout';
import {
  FlexObj,
  getSessionUserInfo,
  setSessionUserInfo,
} from '../../store/ducks/session';
import CityModal from '../cityModal/CityModal';
import CountryModal from '../countryModal/CountryModal';
import DOBModal from '../dobModal/DOBModal';
import GenderModal from '../genderModal/GenderModal';
import NameModal from '../nameModal/NameModal';
import PasswordModal from '../passwordModal/PasswordModal';
import PhoneModal from '../phoneModal/PhoneModal';
import ProfessionModal from '../professionModal/ProfessionModal';
import './Account.scss';
import PicModal from '../picModal/PicModal';

/** interface to describe account props */
interface AccountProps {
  userInfo: FlexObj;
  setUserInfoActionCreator: typeof setSessionUserInfo;
}

const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const { userInfo, setUserInfoActionCreator } = props;

  /** manages the visibility of picture/avatar modal */
  const [picModalVisible, setPicModalVisible] = React.useState<boolean>(false);

  /** manages the visibility of name modal */
  const [nameModalVisible, setNameModalVisible] = React.useState<boolean>(
    false
  );

  /** manages the visibility of date of birth modal */
  const [dobModalVisible, setDobModalVisible] = React.useState<boolean>(false);

  /** manages the visibility of gender modal */
  const [genderModalVisible, setGenderModalVisible] = React.useState<boolean>(
    false
  );

  /** manages the visibility of profession modal */
  const [professionModalVisible, setProfessionModalVisible] = React.useState<
    boolean
  >(false);

  /** manages the visibility of country modal */
  const [countryModalVisible, setCountryModalVisible] = React.useState<boolean>(
    false
  );

  /** manages the visibility of city modal */
  const [cityModalVisible, setCityModalVisible] = React.useState<boolean>(
    false
  );

  /** manages the visibility of password modal */
  const [passwordModalVisible, setPasswordModalVisible] = React.useState<
    boolean
  >(false);

  /** manages the visibility of phone modal */
  const [phoneModalVisible, setPhoneModalVisible] = React.useState<boolean>(
    false
  );

  /** pic */

  /**
   * manages the save request of pic
   * @param {string} requested - the pic/avatar link to save
   */
  const savePic = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, avatar: requested });
    setPicModalVisible(false);
  };

  /** opens the pic/avatar upload modal */
  const openPicModal = () => setPicModalVisible(true);

  /** closes the avatar upload modal */
  const closePicModal = () => setPicModalVisible(false);

  /** name */

  /**
   * manages the save request of name
   * @param {string} requested - the name to save
   */
  const saveName = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, name: requested });
    setNameModalVisible(false);
  };

  /** opens the name modal */
  const openNameModal = () => setNameModalVisible(true);

  /** closes the name modal */
  const closeNameModal = () => setNameModalVisible(false);

  /** date of birth */

  /**
   * manages the save request of date of birth
   * @param {string} requested - the date of birth to save
   */
  const saveDob = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, date_of_birth: requested });
    setDobModalVisible(false);
  };

  /** opens the date of birth modal */
  const openDobModal = () => setDobModalVisible(true);

  /** closes the date of birth modal */
  const closeDobModal = () => setDobModalVisible(false);

  /** gender */

  /**
   * manages the save request of gender
   * @param {string} requested - the gender to save
   */
  const saveGender = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, gender: requested });
    setGenderModalVisible(false);
  };

  /** opens the gender modal */
  const openGenderModal = () => setGenderModalVisible(true);

  /** closes the gender modal */
  const closeGenderModal = () => setGenderModalVisible(false);

  /** profession */

  /**
   * manages the save request of profession
   * @param {string} requested - the profession to save
   */
  const saveProfession = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, profession: requested });
    setProfessionModalVisible(false);
  };

  /** opens the profession modal */
  const openProfessionModal = () => setProfessionModalVisible(true);

  /** closes the profession modal */
  const closeProfessionModal = () => setProfessionModalVisible(false);

  /** city */

  /**
   * manages the save request of city
   * @param {string} requested - the city to save
   */
  const saveCity = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, city: requested });
    setCityModalVisible(false);
  };

  /** opens the city modal */
  const openCityModal = () => setCityModalVisible(true);

  /** closes the city modal */
  const closeCityModal = () => setCityModalVisible(false);

  /** country */

  /**
   * manages the save request of country
   * @param {string} requested - the country to save
   */
  const saveCountry = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, country: requested });
    setCountryModalVisible(false);
  };

  /** opens the country modal */
  const openCountryModal = () => setCountryModalVisible(true);

  /** closes the country modal */
  const closeCountryModal = () => setCountryModalVisible(false);

  /** password */

  /** opens the password modal */
  const openPasswordModal = () => setPasswordModalVisible(true);

  /** closes the password modal */
  const closePasswordModal = () => setPasswordModalVisible(false);

  /** phone */

  /**
   * manages the save request of phone
   * @param {string} requested - the phone to save
   */
  const savePhone = (requested: string) => {
    setUserInfoActionCreator({ ...userInfo, mobile: requested });
    setPhoneModalVisible(false);
  };

  /** opens the phone modal */
  const openPhoneModal = () => setPhoneModalVisible(true);

  /** closes the phone modal */
  const closePhoneModal = () => setPhoneModalVisible(false);

  return (
    <div className="Account-container">
      <div className="Account-pic-container">
        <div className="Account-pic">
          <Avatar src={userInfo.avatar} size={100}>
            <div>{userInfo?.name?.slice(0, 2)}</div>
          </Avatar>
          <div onClick={openPicModal} className="Account-pic-overlay">
            <i className="fas fa-camera"></i>
          </div>
        </div>
        <div className="Account-meta">
          <div className="Account-meta-name">{userInfo.name || ''}</div>
          <div className="Account-meta-tags">
            Connected to{' '}
            <div className="Account-meta-apps">
              <span className="Account-meta-highlight">1</span> App
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="Account-section-container">
        <div className="Account-section-title">Profile</div>
        <div className="Account-section-body">
          <ProfileItemView
            label="name"
            value={userInfo.name}
            requestedHandler={openNameModal}
          />
          <ProfileItemView
            label="date of birth"
            value={userInfo.date_of_birth}
            requestedHandler={openDobModal}
          />
          <ProfileItemView
            label="gender"
            value={userInfo.gender}
            requestedHandler={openGenderModal}
          />
          <ProfileItemView
            label="profession"
            value={userInfo.profession}
            requestedHandler={openProfessionModal}
          />
          <ProfileItemView
            label="city"
            value={userInfo.city}
            requestedHandler={openCityModal}
          />
          <ProfileItemView
            label="country"
            value={userInfo.country}
            requestedHandler={openCountryModal}
          />
        </div>
      </div>
      <Divider />
      <div className="Account-section-container">
        <div className="Account-section-title" />
        <div className="Account-section-body">
          <ProfileItemView
            label="password"
            requestedHandler={openPasswordModal}
            value={
              (
                <div className="Account-dot-container">
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                  <div className="Account-dot" />
                </div>
              ) as any
            }
          />
        </div>
      </div>
      <Divider />
      <div className="Account-section-container">
        <div className="Account-section-title">Contact Info</div>
        <div className="Account-section-body">
          <ProfileItemView
            label="email"
            value={
              (
                <div className="Account-email-container">
                  {userInfo.email}{' '}
                  {userInfo.is_email_verified ? (
                    <div className="Account-tag-success"> verified </div>
                  ) : (
                    <div className="Account-tag-failed"> unverified </div>
                  )}
                </div>
              ) as any
            }
          />
          <ProfileItemView
            label="phone"
            value={userInfo.mobile}
            requestedHandler={openPhoneModal}
          />
        </div>
      </div>
      <PicModal
        visible={picModalVisible}
        saveHandler={savePic}
        cancelHandler={closePicModal}
      ></PicModal>
      <NameModal
        visible={nameModalVisible}
        saveHandler={saveName}
        cancelHandler={closeNameModal}
        selected={userInfo.name}
      ></NameModal>
      <DOBModal
        visible={dobModalVisible}
        saveHandler={saveDob}
        cancelHandler={closeDobModal}
        selected={userInfo.date_of_birth}
      ></DOBModal>
      <GenderModal
        visible={genderModalVisible}
        saveHandler={saveGender}
        cancelHandler={closeGenderModal}
        selected={userInfo.gender}
      ></GenderModal>
      <ProfessionModal
        visible={professionModalVisible}
        saveHandler={saveProfession}
        cancelHandler={closeProfessionModal}
        selected={userInfo.profession}
      ></ProfessionModal>
      <CityModal
        visible={cityModalVisible}
        saveHandler={saveCity}
        cancelHandler={closeCityModal}
        selected={userInfo.city}
      ></CityModal>
      <CountryModal
        visible={countryModalVisible}
        saveHandler={saveCountry}
        cancelHandler={closeCountryModal}
        selected={userInfo.country}
      ></CountryModal>
      <PhoneModal
        visible={phoneModalVisible}
        saveHandler={savePhone}
        cancelHandler={closePhoneModal}
        selected={userInfo.mobile}
      ></PhoneModal>
      <PasswordModal
        visible={passwordModalVisible}
        cancelHandler={closePasswordModal}
      ></PasswordModal>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setUserInfoActionCreator: setSessionUserInfo,
};

/** connect Account to the redux store */
const ConnectedAccount = connect(mapStateToProps, mapDispatchToProps)(Account);

export default withAccountLayout(ConnectedAccount);
