import { Divider } from 'antd';
import React from 'react';
import { BLOG_ENDPOINT } from '../../configs/endpoints';
import UpcomingBadge from '../upcomingBadge/UpcomingBadge';
import './Sider.scss';

const Sider: React.FC = () => {
  const navigateToBlog = () => {
    window.location.href = BLOG_ENDPOINT;
  };

  return (
    <div className="Sider-container">
      <div className="Sider-nav-item Sider-nav-item--active">Personal Info</div>
      <div className="Sider-nav-item">
        Apps <UpcomingBadge />
      </div>
      <div className="Sider-nav-item">
        Security & Activity <UpcomingBadge />
      </div>
      <Divider />
      <div className="Sider-nav-item">
        About <UpcomingBadge />
      </div>
      <div className="Sider-nav-item" onClick={navigateToBlog}>
        Blog
      </div>
    </div>
  );
};

export default Sider;
