import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';

/** reminder types */

/** time reminder types */
export const TIME_REMINDER_TYPE = 'TIME_REMINDER';
export type TIME_REMINDER_TYPE = typeof TIME_REMINDER_TYPE;

/** location reminder types */
export const LOCATION_REMINDER_TYPE = 'LOCATION_REMINDER';
export type LOCATION_REMINDER_TYPE = typeof LOCATION_REMINDER_TYPE;

/** interface of Time Reminder object */
export interface TimeReminderObj {
  type: TIME_REMINDER_TYPE;
  date: string;
  time: string;
}

/** interface of Location Reminder object */
export interface LocationReminderObj {
  type: LOCATION_REMINDER_TYPE;
  location: string;
  trigger: string;
}

/** interface for task object */
export interface TaskObj {
  id: string;
  title: string;
  projectId: string;
  sectionId: string;
  priority: string;
  dueDate: string;
  dueTime: string;
  parent: string;
  order: number;
  expanded: boolean;
  completed: boolean;
  labels: string[];
  reminders: Array<TimeReminderObj | LocationReminderObj>;
}

/** The reducer name */
export const reducerName = 'tasks';

// actions
/** action types */
export const SET_TASKS = 'virtunus/reducer/tasks/SET_TASKS';
export const SET_TASK = 'virtunus/reducer/tasks/SET_TASK';
export const UPDATE_TASKS = 'virtunus/reducer/tasks/UPDATE_TASKS';
export const FIX_TASK_ORDERS = 'virtunus/reducer/tasks/FIX_TASK_ORDERS';

/** interface for SET_TASKS action */
export interface SetTasksAction extends AnyAction {
  tasks: TaskObj[];
  type: typeof SET_TASKS;
}

/** interface for SET_TASK action */
export interface SetTaskAction extends AnyAction {
  task: TaskObj;
  type: typeof SET_TASK;
}

/** interface for UPDATE_TASKS action */
export interface UpdateTasksAction extends AnyAction {
  tasks: TaskObj[];
  type: typeof UPDATE_TASKS;
}

/** interface for FIX_TASK_ORDERS action */
export interface FixTaskOrdersAction extends AnyAction {
  parentId: string;
  type: typeof FIX_TASK_ORDERS;
}

/** Create type for task reducer actions */
export type TasksActionTypes =
  | SetTasksAction
  | SetTaskAction
  | UpdateTasksAction
  | FixTaskOrdersAction
  | AnyAction;

// action creators

/** set tasks action creator
 * @param {TaskObj[]} tasks - an array of task items
 * @returns {SetTasksAction} - an action to set tasks in store
 */
export const setTasks = (tasks: TaskObj[]): SetTasksAction => {
  return {
    tasks,
    type: SET_TASKS,
  };
};

/** set task action creator
 * @param {TaskObj} task - a task object
 * @returns {SetTaskAction} - an action to set task in store
 */
export const setTask = (task: TaskObj): SetTaskAction => ({
  task,
  type: SET_TASK,
});

/** update tasks action creator
 * @param {TaskObj[]} tasks - an array of task items
 * @returns {UpdateTasksAction} - an action to update tasks in store
 */
export const updateTasks = (tasks: TaskObj[]): UpdateTasksAction => {
  const ids = tasks.map((task: TaskObj) => task.id);
  return {
    tasks,
    ids,
    type: UPDATE_TASKS,
  };
};

/**
 * set the task orders correctly
 * @param {string} projectId - the project id
 * @param {string} sectionId - the section id
 * @param {string} parentId - the parent task id
 * @returns {FixTaskOrdersAction} - an action to fix the tasks order given the parent task id
 */
export const fixTaskOrders = (
  projectId: string,
  sectionId: string,
  parentId: string
): FixTaskOrdersAction => ({
  projectId,
  sectionId,
  parentId,
  type: FIX_TASK_ORDERS,
});

// the reducer

/** interface for tasks state in redux store */
type TasksState = TaskObj[];

/** Create an immutable tasks state */
export type ImmutableTasksState = SeamlessImmutable.ImmutableArray<TasksState>;

/** initial tasks state */
const initialState: ImmutableTasksState = SeamlessImmutable([]);

/** the tasks reducer function */
export default function reducer(
  state: ImmutableTasksState = initialState,
  action: TasksActionTypes
): ImmutableTasksState {
  switch (action.type) {
    case SET_TASKS:
      return SeamlessImmutable(action.tasks);
    case SET_TASK:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) => iterateTask.id !== action.task.id
        ),
        action.task,
      ]);
    case UPDATE_TASKS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) => !action.ids.includes(iterateTask.id)
        ),
        ...action.tasks,
      ]);
    case FIX_TASK_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state as any,
          (iterateTask: TaskObj) =>
            !(
              iterateTask.parent === action.parentId &&
              iterateTask.projectId === action.projectId &&
              iterateTask.sectionId === action.sectionId
            )
        ),
        ...lodash.map(
          lodash.orderBy(
            lodash.filter(
              state,
              (iterateTask: TaskObj) =>
                iterateTask.parent === action.parentId &&
                iterateTask.projectId === action.projectId &&
                iterateTask.sectionId === action.sectionId
            ),
            ['order'],
            ['asc']
          ),
          (iterateTask: TaskObj, index: number) => ({
            ...iterateTask,
            order: index,
          })
        ),
      ]);
    default:
      return state;
  }
}

// selectors

/** returns the tasks list
 * @param {Partial<Store>} state - the redux store
 * @return { TaskObj[] } - the existing tasks
 */
export function getTasks(state: Partial<Store>): TaskObj[] {
  return (state as any)[reducerName];
}

/** returns the tasks list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing tasks length
 */
export function getTasksLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the next available order of new task
 * @param {Partial<Store>} state - the redux store
 * @param {projectId} string - project id
 * @param {sectionId} string - section id
 * @return { number } - the new available order index
 */
export function getNewTasksOrderIndex(
  state: Partial<Store>,
  projectId: string,
  sectionId: string
): number {
  return lodash.filter((state as any)[reducerName], { projectId, sectionId })
    .length;
}

/** returns the tasks related to project id and section id
 * @param {Partial<Store>} state - the redux store
 * @param {projectId} string - project id
 * @param {sectionId} string - section id
 * @return { TaskObj } - the tasks list related to project id and section id
 */
export function getProjectAndSectionBasedTasks(
  state: Partial<Store>,
  projectId: string,
  sectionId: string
): TaskObj[] {
  return lodash.filter((state as any)[reducerName], { projectId, sectionId });
}

/** returns the tasks count present in the project
 * @param {Partial<Store>} state - the redux store
 * @param {number} projectId - the requested project id
 * @return { number } - the existing tasks count
 */
export function getTasksCountByProjectId(
  state: Partial<Store>,
  projectId: string
): number {
  return lodash.filter((state as any)[reducerName], { projectId }).length;
}

/** returns the tasks present with the label id
 * @param {Partial<Store>} state - the redux store
 * @param {number} labelId - the requested label id
 * @return { number } - the existing tasks
 */
export function getTasksByLabelId(
  state: Partial<Store>,
  labelId: string
): TaskObj[] {
  return lodash.filter((state as any)[reducerName], (iterTask: TaskObj) =>
    iterTask.labels.includes(labelId)
  );
}

/** returns the tasks count present with the label id
 * @param {Partial<Store>} state - the redux store
 * @param {number} labelId - the requested label id
 * @return { number } - the existing tasks count
 */
export function getTasksCountByLabelId(
  state: Partial<Store>,
  labelId: string
): number {
  return lodash.filter((state as any)[reducerName], (iterTask: TaskObj) =>
    iterTask.labels.includes(labelId)
  ).length;
}

/** returns the task obj given task id if exists; otherwise, null
 * @param {Partial<Store>} state - the redux store
 * @param {string} taskId - the task id
 * @return { ProjectObj | null } - the existing task or null
 */
export function getTaskById(state: Partial<Store>, taskId: string): TaskObj {
  return lodash.find((state as any)[reducerName], { id: taskId }) || null;
}
